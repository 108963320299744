@import 'bootstrap/dist/css/bootstrap.min.css';
@import '@fortawesome/fontawesome-free/css/all.css';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
// @import "~swiper/swiper-bundle.min.css";
@import 'variables';

:root {
  --text-1: #5a5a5a;
  --smartflip-blue: #01579b; // Brand Dark blue
  --secondary: #62adf8;
  --light-blue: #9dcdfb;
}

html,
body {
  background: #eee;
  width: 100%;
  min-height: 100%;
  height: 100%;

  .full-screen-modal {
    max-width: none !important;
    width: 100vw;
    display: block;

    .mat-dialog-container {
      max-width: none;
      width: 100vw;
      height: 100vh;
      background-color: transparent;
    }
  }
}

mat-form-field {
  margin-right: 2%;
}

.fill-space {
  flex: 1 1 auto;
}

.mat-expansion-panel-header,
.mat-expansion-panel-header:focus {
  background-color: $primary;
  color: white !important;

  .mat-expansion-panel-header-title,
  .mat-expansion-indicator:after {
    color: white !important;
  }

  &.mat-expanded,
  &.mat-expanded:focus,
  &.mat-expanded:active,
  &.mat-expanded:hover {
    color: white;
    background-color: $accent;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    &.mat-expansion-panel-header {
      margin-bottom: 1vh;
    }
  }

  &:focus * {
    color: inherit !important;
  }

  &:hover *,
  &:hover .mat-expansion-indicator:after {
    color: #666 !important;
  }
}

.mdc-card {
  padding: 16px;
}

.RepairCostsModal {
  .mat-dialog-container {
    padding: 0;
  }
}

.blue-snackbar {
  background-color: $primary;
  color: white;

  .mdc-button__label {
    color: white;
    font-weight: 900;
  }
}

.ImageUpload__Tooltip {
  background-color: white;
  border-radius: 5px;
  box-shadow: rgba(90, 90, 90, 0.35) 2px 2px 5px 3px;
  max-width: 30rem;
  width: 30rem;
  padding: 1rem;

  & > * {
    max-width: 30rem !important;
    width: 30rem;
  }
}

.green-snackbar {
  --mdc-snackbar-container-color: green;
  background-color: green !important;
  color: white;
}

.smart-flip-map-marker {
  padding: 0.5rem;
  width: 3.5rem;
  height: 3.5rem;
  overflow: visible;

  .material-symbols-outlined {
    background: white;
    border-radius: 50%;
    font-size: 2.5rem;
    color: $primary;

    &.marker-source-property {
      color: darkred;
      padding: 0.55rem;
    }

    &.highlighted,
    &:hover {
      color: white;
      background-color: $primary;

      & + .map-label {
        opacity: 1;
      }
    }
  }
}

.map-label {
  position: absolute;
  width: fit-content;
  min-width: 8rem;
  z-index: 10000;
  top: -1rem;
  left: 2rem;
  padding: 0.25rem;
  opacity: 0;
  transition: opacity 0.6s;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: gray 2px 2px 5px;
  font-size: 0.9rem;
  color: $primary;
  text-wrap: nowrap;
}

/* Useitful class overrides */
.uf-button-primary {
  background-color: $primary !important;
  border-color: $primary !important;
}

.uf-free-badge {
  display: none !important;
}

.uf-progress-bar {
  background-color: $primary !important;
}

ion-toast.error-toaster::part(container) {
  background-color: darkred;
  font-weight: bold;
}
