// Import material theming functions
@use '@angular/material' as mat;
@import 'theme';

// Copy the palettes from your selected theme (usually theme.scss).
$app-primary: mat.m2-define-palette($flip-app-primary, 900);
$app-accent: mat.m2-define-palette(mat.$m2-light-blue-palette, 500);
// The warn palette is optional (defaults to red).
$app-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create your Sass color vars (will be available in all the project)
$primary: mat.m2-get-color-from-palette($app-primary);
$accent: mat.m2-get-color-from-palette($app-accent);
$warn: mat.m2-get-color-from-palette($app-warn);

body {
  --primary: $primary;
}
